import PropTypes from 'prop-types';
import React from 'react';

import { getSeoConfig } from '../../../../constants/defaultSeoProps';
import Layout from '../../../../components/Layout';
import MSSPHeroimage from '../../../../assets/services/MSSPHeroimage.png';
import PageHeader from '../../../../components/PageHeader';
import ServiceSection from '../../../../components/ServiceSection';
import s from './MSSP.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  approachDetails: PropTypes.shape({}).isRequired,
  coreServicesDetails: PropTypes.shape({}).isRequired,
  techStackDetails: PropTypes.shape({}).isRequired,
  transparentOperationsDetails: PropTypes.shape({}).isRequired,
  whyPartnerWithUsDetails: PropTypes.shape({}).isRequired,
  gettingStartedDetails: PropTypes.shape({}).isRequired,
  companyQuote: PropTypes.shape({
    footerDescription: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/mssp.png',
  ogTitle: 'Managed Security Services (MSSP) | Tech Holding',
  ogDesc:
    '24/7 security operations, threat detection, and response from our expert SOC team. Get enterprise-grade security monitoring tailored to your business.',
  ogPath: '/services/security/mssp',
};

const updatedSeo = getSeoConfig(seoParams);

const Security = (props) => {
  const {
    title,
    description,
    approachDetails,
    coreServicesDetails,
    techStackDetails,
    transparentOperationsDetails,
    whyPartnerWithUsDetails,
    gettingStartedDetails,
    companyQuote,
  } = props;

  return (
    <Layout
      currentPage="/services/security/mssp"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      {/* Header */}
      <PageHeader title={title} description={description} />

      <div className={s.pageContainer}>
        {/* Hero Image */}
        <img src={MSSPHeroimage} alt={title} />

        {/* Our Approach */}
        <div className={s.approachSection}>
          <ServiceSection {...approachDetails} />
        </div>

        {/* Core Services */}
        <ServiceSection {...coreServicesDetails} />

        {/* Technology Stack */}
        <ServiceSection {...techStackDetails} />

        {/* Transparent Operations */}
        <ServiceSection {...transparentOperationsDetails} />

        {/* Why Choose Us */}
        <ServiceSection {...whyPartnerWithUsDetails} />

        {/* Getting Started */}
        <ServiceSection {...gettingStartedDetails} />
      </div>
    </Layout>
  );
};

Security.propTypes = propTypes;
export default Security;
