import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ListDisplay from '../ListDisplay';
import s from './ServiceSection.module.scss';

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  footerDescription: PropTypes.string,
  singleColumn: PropTypes.bool,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      titleHref: PropTypes.string,
      points: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

const defaultProps = {
  title: '',
  description: '',
  footerDescription: '',
  singleColumn: false,
  topics: [],
};

const ServiceSection = (props) => {
  const { title, description, footerDescription, topics, singleColumn } = props;

  return (
    <div className={s.container}>
      {/* Title */}
      {title !== '' && <h2>{title}</h2>}

      {/* Description */}
      {typeof description === 'string' && description !== '' && <p>{description}</p>}
      {Array.isArray(description) && (
        <div>
          {description.map((text) => (
            <>
              <p key={text}>{text}</p>
              <br />
            </>
          ))}
        </div>
      )}

      {/* Topics */}
      {topics.length > 0 && (
        <div
          className={classNames({
            [s.sectionGrid]: true,
            [s.singleColumn]: singleColumn,
          })}
        >
          {topics.map((topic) => (
            <div className={s.topic} key={topic.title}>
              {/* Topic Title */}
              {topic.title !== undefined && (
                <h3>
                  {topic.titleHref ? <a href={topic.titleHref}>{topic.title}</a> : topic.title}
                </h3>
              )}

              {/* Topic Description */}
              {topic.description !== undefined && (
                <p className={s.description}>{topic.description}</p>
              )}

              {/* Topic Points */}
              <div className={s.listDisplay}>
                <ListDisplay {...topic} />
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Footer Description */}
      {footerDescription !== '' && <p className={s.footerDescription}>{footerDescription}</p>}
    </div>
  );
};

ServiceSection.propTypes = propTypes;
ServiceSection.defaultProps = defaultProps;

export default ServiceSection;
