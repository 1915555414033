import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import MSSP from '../../../containers/Services/Security/MSSP';

const title = 'Managed Security Services';
const description =
  "In today's threat landscape, maintaining robust security operations requires constant vigilance, specialized expertise, and significant resources. As your Managed Security Service Provider (MSSP), we augment your team with battle-tested security professionals who understand both the technical intricacies of modern infrastructure and the evolving threat landscape.";
const approachDetails = {
  title: 'Our Approach',
  description:
    'We believe that effective security operations require more than just tools and automation. Our security professionals work as an extension of your team, bringing context-aware protection that aligns with your business objectives. We combine continuous monitoring, proactive threat hunting, and rapid incident response with a deep understanding of your infrastructure and application stack.',
};
const coreServicesDetails = {
  title: 'Core Services',
  topics: [
    {
      title: 'Security Operations Center',
      description:
        'Our 24/7 SOC team provides continuous monitoring and response capabilities across your entire infrastructure. We leverage advanced SIEM capabilities to collect, correlate, and analyze security events from multiple sources, enabling us to detect and respond to threats in real-time.',
      startingText: 'Key features:',
      points: [
        'Real-time threat detection and analysis',
        'Incident investigation and response',
        'Regular threat hunting operations',
        'Security event correlation and analysis',
        'Custom detection rule development',
      ],
    },
    {
      title: 'Infrastructure Security Management',
      description:
        'We implement and maintain the security controls that protect your infrastructure, ensuring your systems stay resilient against evolving threats.',
      startingText: 'Our services include:',
      points: [
        'Firewall and network security management',
        'Access control and identity management',
        'Endpoint protection deployment and monitoring',
        'Cloud security posture management',
        'Regular vulnerability assessments and penetration testing',
      ],
    },
    {
      title: 'Security Advisory Services',
      description:
        'Our security professionals provide strategic guidance to help you make informed decisions about your security posture.',
      startingText: 'We Offer:',
      points: [
        'Security architecture review and recommendations',
        'Threat modeling',
        'Risk assessment and mitigation planning',
        'Security roadmap development',
        'Incident response planning',
        'Security awareness training',
      ],
    },
  ],
};
const techStackDetails = {
  title: 'Our Technology Stack',
  description:
    'While we maintain vendor neutrality and can work with your existing tools, we have deep expertise in industry-leading security platforms:',
  topics: [
    {
      points: [
        'SIEM: Splunk Enterprise Security, ELK Stack',
        'EDR: CrowdStrike, SentinelOne',
        'Network Security: Palo Alto Networks, Cisco',
        'Cloud Security: AWS Security Hub, Azure Defender, Google Cloud Security Command Center',
        'Vulnerability Management: Tenable, Qualys',
      ],
    },
  ],
};
const transparentOperationsDetails = {
  title: 'Transparent Operations',
  description:
    "We believe in complete transparency in our security operations. You'll have access to:",
  topics: [
    {
      points: [
        'Real-time security dashboards',
        'Detailed incident reports',
        'Regular performance metrics',
        'Monthly security posture reviews',
        'Custom reporting based on your requirements',
      ],
    },
  ],
};
const whyPartnerWithUsDetails = {
  title: 'Why Partner With Us',
  description: 'Our MSSP services stand out through:',
  singleColumn: true,
  topics: [
    {
      points: [
        '<strong>Technical depth:</strong> Our security professionals have extensive experience in both offensive and defensive security',
        '<strong>Context awareness:</strong> We learn your business context to provide relevant, actionable security insights',
        "<strong>Proactive approach:</strong> We don't just monitor; we actively hunt for threats and continuously improve security controls",
        '<strong>Automation focus:</strong> We automate routine tasks to focus human expertise on complex security challenges',
        '<strong>Collaborative mindset:</strong> We work as an extension of your team, not just as an external service provider',
      ],
    },
  ],
};
const gettingStartedDetails = {
  title: 'Getting Started',
  description:
    'Our onboarding process is designed to ensure a smooth transition and rapid time-to-value:',
  singleColumn: true,
  topics: [
    {
      showNumbers: true,
      points: [
        'Initial assessment of your current security posture',
        'Definition of security objectives and success metrics',
        'Development of custom playbooks and procedures',
        'Phased implementation of monitoring and controls',
        'Continuous optimization based on observed patterns and emerging threats',
      ],
    },
  ],
  footerDescription:
    'Contact our security team to discuss how we can enhance your security operations.',
};

const details = {
  title,
  description,
  approachDetails,
  coreServicesDetails,
  techStackDetails,
  transparentOperationsDetails,
  whyPartnerWithUsDetails,
  gettingStartedDetails,
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
};

const MSSPPage = ({ data }) => <MSSP {...details} companyQuote={data.contentfulCompanyQuote} />;

MSSPPage.propTypes = propTypes;

export default MSSPPage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
