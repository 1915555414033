import PropTypes from 'prop-types';
import React from 'react';

import s from './ListDisplay.module.scss';

const propTypes = {
  points: PropTypes.arrayOf(PropTypes.string),
  startingText: PropTypes.string,
  tailingText: PropTypes.string,
  showNumbers: PropTypes.bool,
};

const defaultProps = {
  points: [],
  startingText: '',
  tailingText: '',
  showNumbers: false,
};

const ListDisplay = ({ startingText, points, tailingText, showNumbers }) => {
  const ListElement = showNumbers ? 'ol' : 'ul';
  return (
    <div className={s.container}>
      {startingText !== '' && <p>{startingText}</p>}
      <ListElement>
        {points.map((point) => (
          // eslint-disable-next-line react/no-danger
          <li key={point} dangerouslySetInnerHTML={{ __html: point }} />
        ))}
      </ListElement>
      {tailingText !== '' && <p>{tailingText}</p>}
    </div>
  );
};

ListDisplay.propTypes = propTypes;
ListDisplay.defaultProps = defaultProps;

export default ListDisplay;
